import React, { useState, useEffect, useCallback } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateEdit from '../components/CreateEditProjectRisk';
import SpinnerComponent from '../components/LoadingSpinner';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DocumentList from '../components/lists/DocumentList';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
/* This is a view for returning details on a project risk data object */

export async function loadData(id) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/risks/${id}`;
  //try {
  const response = axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProjectRisk({ id }) {
  const [data, setData] = useState([]);
  const [editOpen, setEditOpen] = useState(false);

  //axios error message
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
  }, [errMsg])

  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("system_role") === "4" || localStorage.getItem("system_role") === "1" || localStorage.getItem("system_role") === "2") {
      setIsSystemAdmin(true)
    }
  }, [])

  useEffect(() => {
  }, [isSystemAdmin])


  const handleClose = () => {
    setEditOpen(false);
  }

  const isOpen = () => {
    handleClose();
  }

  const EditData = () => {
    setEditOpen(true);
  }

  const addControlData = () => {
    getData()
  }

  const getData = useCallback(async () => {
    const jsonData = await loadData(id);
    if (jsonData.status === 200) {
      setData(jsonData.data);
    } else {
      setErrMsg(`${jsonData}`)
    }
  }, [id])

  useEffect(() => {
    if (data.length === 0) {
      getData();
    }

  });

  useEffect(() => {

  }, [data]);

  return (
    <>
      {errMsg ? (
        <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">{errMsg}</Alert>
        </Stack>
      ) : (
        <></>
      )}
      <div className="details-view projectRisk">
        {(!data && <SpinnerComponent />)}
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/system-administration">
            System Administration
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/list`}>
            Projects
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}`}>
            {data?.project?.name}
          </Link>
          <Link underline="hover" color="inherit" href={`/projects/${data?.project?.id}/risks`}>
            Risks
          </Link>
          <Typography color="text.primary">{data?.name}</Typography>
        </Breadcrumbs>
        <div className='leftCol'>
          {isSystemAdmin ? <Button variant="contained" onClick={EditData}>Edit</Button> : <></>}
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Name</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.name}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Description</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.description}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Project Control</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Link href={`/projects/${data?.project?.id}/controls/${data?.project_control?.id}`}>{data?.project_control?.control?.name}</Link>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Audit Test</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                  <Link href={`/projects/${data?.project?.id}/audit_tests/${data?.audit_test?.id}`}>{data?.audit_test?.name}</Link>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Likelihood</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.current_likelihood?.name}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Impact</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.risk_impact?.name}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Score</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.risk_score?.name}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Category</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.risk_category?.name}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Status</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.risk_status?.name}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Owner</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Link href={`/users/${data?.owner?.id}`}>{data?.owner?.email}</Link>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Additional Stakeholders</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ul>
                      {(data?.additional_stakeholders && (
                        data?.additional_stakeholders?.map((stakeholder) => {
                          return <li>
                            <Link href={`/users/${stakeholder.id}`}>{stakeholder.email}</Link>
                          </li>
                        })
                      )
                      )}
                    </ul>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Technology</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.technology}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Affected Assets</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.affected_assets}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Comments</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.comments}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Additional Notes</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data?.additional_notes}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow >
                  <StyledTableCell component="th" scope="row">
                    <strong>Keywords</strong>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ul>
                      {(data?.keywords && (
                        data?.keywords?.map((keyword) => {
                          return <li>
                            <Link href={`/keywords/${keyword.keyword.id}`}>{keyword?.keyword?.name}</Link>
                          </li>
                        })
                      )
                      )}
                    </ul>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className='rightCol'>
          <h2>Documents</h2>
          <DocumentList documentListData={data?.documents} dataType={`risks`} dataId={data?.id} />
        </div>

        <Dialog
          //fullScreen
          open={editOpen}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className={`dialog edit`}
        >
          <DialogActions className="create-edit-dialog">
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <DialogTitle>Edit {data?.name}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <CreateEdit editDataObj={data} projectId={data?.project?.id} controlData={addControlData} isOpen={isOpen} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}