import React, { useState, useEffect, useMemo, useCallback, lazy, Suspense } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../css/ProjectFrameworksCharts.css';
import '../css/GovernanceCharts.css'
import AWSControlListProject from '../components/lists/AWSReportProject';
import SpinnerComponent from '../components/LoadingSpinner';
import CapPoamsComplianceCharts from '../components/graphs/CapPoamsComplianceCharts';
const ProjectFrameworkList = lazy(() => import('../components/lists/ProjectFrameworkList'));
const ProjectControlList = lazy(() => import('../components/lists/ProjectControlList'))
const AssessmentList = lazy(() => import('../components/lists/AssessmentList'))
const ExceptionList = lazy(() => import('../components/lists/ExceptionList'))
const ControlStatusChart = lazy(() => import('../components/graphs/ControlStatus'))
const ControlPhaseChart = lazy(() => import('../components/graphs/ControlPhase'))
const ControlFamilyChart = lazy(() => import('../components/graphs/ControlFamily'))
const ControlClassChart = lazy(() => import('../components/graphs/ControlClass'))
const ControlMITPercChart = lazy(() => import('../components/graphs/ControlMITPerc'))
const ControlAssessmentChart = lazy(() => import('../components/graphs/ControlAssessment'))
const ControlExceptionChart = lazy(() => import('../components/graphs/ControlException'))


/* 
********START all endpoints called
*/
export async function loadFrameworkVersionData(id) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/framework_versions/?project_id=${id}&offset=0&limit=100`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function loadProjectControlData(id) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/projects/project_controls/${id}?offset=0&limit=1500`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function loadAssessmentData(projectId) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/projects/assessments/${projectId}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getAWSControls(id) {
  var url = `${process.env.REACT_APP_API_URL}/aws_controls/project_id/${id}`
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getCapPoams(id) {
  var url = `${process.env.REACT_APP_API_URL}/cap_poams/project/${id}`
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function loadExceptionData(projectId) {
  //call data endpoint for data type to set rows and columns
  var url = `${process.env.REACT_APP_API_URL}/exceptions/?project_id=${projectId}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getChartData(id) {
  var url = `${process.env.REACT_APP_API_URL}/projects/${id}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getProjectData() {
  var url = `${process.env.REACT_APP_API_URL}/projects/get_user_projects/${localStorage.getItem("userid")}`
  if (localStorage.getItem("system_role") === "4") {
    url = `${process.env.REACT_APP_API_URL}/projects/tenant/`
  }
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getCapPoamChartData(projectId) {
  //console.log('projectData', projectData)
  var url = `${process.env.REACT_APP_API_URL}/dashboards/compliance/cap_poam/metrics?project_id=${projectId}`
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

/* 
********END all endpoints called
*/

export default function Governance() {
  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const [projects, setProjects] = useState([{ value: "0", label: "Please select..." }]);
  const [projectId, setProjectId] = useState(null);
  const [projectControlListData, setProjectControlListData] = useState(null);
  const [assessmentListData, setAssessmentListData] = useState(null);
  const [exceptionListData, setExceptionListData] = useState(null);
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAwsReports, setShowAwsReports] = useState(false);
  const [awsReportsExist, setAwsReportExist] = useState(false);
  const [projectName, setProjectName] = useState();
  const [capPoamsExist, setCapPoamsExist] = useState(false);
  const [capPoamsByMonth, setCapPoamsByMonth] = useState([]);
  const [capPoamsByStatus, setCapPoamsByStatus] = useState([]);
  const [capPoamsByCriticality, setCapPoamsByCriticality] = useState([]);
  const [value, setValue] = useState(0);
  const [frameworks, setFrameworks] = useState()

  const { control } = useForm({
    defaultValues: {
      project: {},
    }
  });

  const getProjectControlData = useCallback(async (id) => {
    const jsonData = await loadProjectControlData(id);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    setProjectControlListData(jsonData)
  }, [])

  const getAssessmentData = useCallback(async (projectId) => {
    const jsonData = await loadAssessmentData(projectId);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    setAssessmentListData(jsonData)
  }, [])

  const getExceptionData = useCallback(async (projectId) => {
    const jsonData = await loadExceptionData(projectId);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    setExceptionListData(jsonData)
  }, [])

  const checkIfAWSControlsExist = useCallback(async (id) => {
    const jsonData = await getAWSControls(id);
    if (jsonData.data !== "No controls found") {
      setAwsReportExist(true)
    }
    if (jsonData.data === "No controls found") {
      setAwsReportExist(false)
    }
  }, [])

  const checkIfCapPoamsExist = useCallback(async (id) => {
    const jsonData = await getCapPoams(id);
    if (jsonData?.data?.length > 0) {
      setCapPoamsExist(true)
      // get capPoamChartData
      let response = await getCapPoamChartData(id)
      setCapPoamsByMonth({
        series: [
          {
            name: 'Actual',
            data: response?.data?.monthly
          }]
      })
      setCapPoamsByStatus({
        series: [
          {
            name: 'Actual',
            data: response?.data?.status
          }]
      })
      setCapPoamsByCriticality({
        series: [
          {
            name: 'Actual',
            data: response?.data?.criticality
          }
        ]
      }
      )
    }
    else {
      setCapPoamsExist(false)
    }
  }, [])

  async function getCharts(id) {
    setLoading(true)
    const jsonDataProject = await getChartData(id);
    setProjectData(jsonDataProject.data);
    setLoading(false)
  }

  const getFrameworkVersionData = async(id) => {
    const jsonData = await loadFrameworkVersionData(id);
    if (jsonData?.data?.items) {
      var framework_versions = []
      jsonData?.data?.items?.forEach((item) => {
        let framework = {id: item.id, name: `${item.version_prefix} ${item.framework.name} ${item.version_suffix}`, framework_id: item.framework.id}
        framework_versions.push(framework)
      })
      setFrameworks(framework_versions)
    }
  }

  const handleChangeProjectSelect = async (event) => {
    setProjectId(event.target.value)
    getCharts(event.target.value)
    setProjectControlListData(null)
    getProjectControlData(event.target.value)
    setAssessmentListData(null)
    getAssessmentData(event.target.value)
    setExceptionListData(null)
    getExceptionData(event.target.value)
    checkIfAWSControlsExist(event.target.value)
    checkIfCapPoamsExist(event.target.value)
    let project = projects.filter(item => item.value === event.target.value)
    setProjectName(project[0].label)
    setShowAwsReports(false)
    //getFrameworksData
    getFrameworkVersionData(event.target.value)
  };

  useEffect(() => {
    //console.log('projectControlListData', projectControlListData)
  }, [projectControlListData])

  useEffect(() => {
    //console.log('assessmentListData', assessmentListData)
  }, [assessmentListData])


  const getDropDownOptions = useCallback(async () => {
    const jsonData = await getProjectData();
    var projectOptions;
    if (jsonData?.data) {
      projectOptions = jsonData.data.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects([selectOption, ...projectOptions])
    }

  }, [selectOption])


  useEffect(() => {
    getDropDownOptions();
  }, [getDropDownOptions]);

  useEffect(() => {
  }, [projectId]);


  useEffect(() => {
    //console.log('exceptionListData', exceptionListData)
  }, [exceptionListData])

  useEffect(() => {
    //console.log(projectId)
  }, [projectId])

  useEffect(() => {
    //console.log('projectData', projectData)
  }, [projectData])

  useEffect(() => {
  }, [capPoamsByMonth, capPoamsByCriticality, capPoamsByStatus])

  useEffect(() => {
    console.log(frameworks)
  }, [frameworks])

  useEffect(() => {
    if (!awsReportsExist || !capPoamsExist) {
      setValue(0)
    }
  },[awsReportsExist, capPoamsExist])

  const onChange = async (e, value) => {
    setValue(value);
  }

  return (
    <>
      <div className='tab-detail'>
        <h1>Governance</h1>
        <p>
          Broad Overview of Project Frameworks, Controls, Exceptions, and Assessments
        </p>
        {showAwsReports === false &&
          <div className="form-field control">
            <label>Select a Project</label>
            <Controller
              name="project"
              control={control}
              render={({ field }) =>
                <Select
                  {...field}
                  onChange={handleChangeProjectSelect}
                  value={projectId ? projectId : 0}
                  inputProps={{ "data-testid": "project-select" }}
                >
                  {
                    projects?.map((project) => {
                      return <MenuItem
                        value={project.value}
                        key={project.value}>
                        {project.label}
                      </MenuItem>
                    })
                  }
                </Select>
              }
            />
          </div>}
        <AppBar position="static">
          <Tabs value={value} onChange={onChange}>
            <Tab label="Control Metrics" />
            {awsReportsExist && <Tab label="AWS Reports" />}
            {capPoamsExist && <Tab label="CAP / POAM Metrics" />}
          </Tabs>
        </AppBar>
        {value === 0 &&
          <div className="panel">
            {loading ? <SpinnerComponent /> :
              <div className='governance charts'>
                <h2>Project Frameworks</h2>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        key='project'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">Project</TableCell>
                        <TableCell align="left"><a href={`/projects/${projectData?.id}`}>{projectData?.name}</a></TableCell>
                      </TableRow>
                      <TableRow
                        key='framework'
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left">Frameworks</TableCell>
                        <TableCell align="left">
                          <ul>
                            {frameworks?.map((item) => {
                              return <li><a href={`/frameworks/${item?.framework_id}/framework_versions/${item?.id}`}>{item?.name}</a></li>
                            })}
                          </ul>

                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Suspense fallback={<SpinnerComponent />}>
                  <ControlClassChart projectId={projectData?.id} />
                </Suspense>
                <Suspense fallback={<SpinnerComponent />}>
                  <ControlFamilyChart projectId={projectData?.id} />
                </Suspense>
                <Suspense fallback={<SpinnerComponent />}>
                  <ControlPhaseChart projectId={projectData?.id} />
                </Suspense>
                <Suspense fallback={<SpinnerComponent />}>
                  <ControlStatusChart projectId={projectData?.id} />
                </Suspense>
                <div className="row2">
                  <Suspense fallback={<SpinnerComponent />}>
                    <ControlMITPercChart projectId={projectData?.id} />
                  </Suspense>
                  <Suspense fallback={<SpinnerComponent />}>
                    <ControlAssessmentChart projectId={projectData?.id} />
                  </Suspense>
                  <Suspense fallback={<SpinnerComponent />}>
                    <ControlExceptionChart projectId={projectData?.id} />
                  </Suspense>
                </div>

                {projectControlListData &&
                  <div className="panel">
                    <h2>Controls</h2>
                    <Suspense fallback={<SpinnerComponent />}>
                      <ProjectControlList projectId={projectData?.id} projectControlData={projectControlListData} />
                    </Suspense>
                  </div>
                }

                {exceptionListData &&
                  <div className="panel">
                    <h2>Exceptions</h2>
                    <Suspense fallback={<SpinnerComponent />}>
                      <ExceptionList projectId={projectData?.id} exceptionData={exceptionListData} />
                    </Suspense>
                  </div>
                }
                {assessmentListData && <div className="panel">
                  <h2>Assessments</h2>
                  <Suspense fallback={<SpinnerComponent />}>
                    <AssessmentList projectId={projectData?.id} assessmentData={assessmentListData} />
                  </Suspense>
                </div>}
              </div>
            }
            {projectId != null && <><h2>Frameworks</h2>
              <Suspense fallback={<SpinnerComponent />}>
                <ProjectFrameworkList projectId={projectId} />
              </Suspense></>}
          </div>}

        {value === 1 &&
          <>
            <h2><a href={`/projects/${projectId}`}>{projectName}</a></h2>
            <AWSControlListProject projectId={projectId} />
          </>
        }
        {value === 2 &&
          <>
            <h2><a href={`/projects/${projectId}`}>{projectName}</a></h2>
            <>
              <CapPoamsComplianceCharts project={{name: projectName, id: projectId}}capPoamsByMonth={capPoamsByMonth} capPoamsByStatus={capPoamsByStatus} capPoamsByCriticality={capPoamsByCriticality} />
            </>
          </>
        }
      </div>
    </>
  );
}