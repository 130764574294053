import React, { useState, useEffect, useMemo, useCallback, lazy, Suspense } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import SpinnerComponent from '../components/LoadingSpinner';
const WBSList = lazy(() => import('../components/lists/WBSList'));
const WBSCharts = lazy(() => import('../components/graphs/WBSCharts'));

/* 
********START all endpoints called
*/

export async function getProjectData() {
  var url = `${process.env.REACT_APP_API_URL}/projects/get_user_projects/${localStorage.getItem("userid")}`
  if (localStorage.getItem("system_role") === "4") {
    url = `${process.env.REACT_APP_API_URL}/projects/tenant/`
  }
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getProjectChartData(id) {
  var url = `${process.env.REACT_APP_API_URL}/tasks/charts/${id}`;
  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getWbsChartData(projectId, wbsid) {
  var url;
  if (wbsid !== "0") {
    url = `${process.env.REACT_APP_API_URL}/tasks/charts/${projectId}?wbs_id=${wbsid}`;
  }

  const response = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;
}

export async function getWBSData(projectId) {
  //call data endpoint for data type to set rows and columns
  var method = "GET";
  var url = `${process.env.REACT_APP_API_URL}/wbs/project/${projectId}`

  const response = await axios(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.access_token}`,
    },
  }).then(result => {
    return result;
  }).catch(error => { return error.message; })
  return response;

}

/* 
********END all endpoints called
*/

export default function ProjectStudio() {
  //default option when no value selected in drop down
  const selectOption = useMemo(() => { return { value: "0", label: "Please select..." } }, [])
  const [projects, setProjects] = useState([{ value: "0", label: "Please select..." }]);
  const [wbsSelect, setWbsSelect] = useState([{ value: "0", label: "Please select..." }]);
  const [wbsData, setWbsData] = useState([]);
  const [projectId, setProjectId] = useState();
  const [wbsId, setWbsId] = useState();
  const [chartData, setChartData] = useState()
  const [projectData, setProjectData] = useState();
  const [noWBSData, setNoWBSData] = useState(false)

  const { control } = useForm({
    defaultValues: {
      project: {},
      wbs: {},
    }
  });

  const handleChangeProjectSelect = (event) => {
    setProjectId(event.target.value)
    getCharts(event.target.value)
    getProjectChartData(event.target.value)
    getWBS(event.target.value)
    getProjectData()
  };

  const handleChangeWbsSelect = (event) => {
    setWbsId(event.target.value)
    getWBSCharts(event.target.value)
    getWbsChartData(projectId, event.target.value)
  };

  const getDropDownOptions = useCallback(async () => {
    const jsonData = await getProjectData();
    var projectOptions;
    if (jsonData?.data?.length > 0) {
      projectOptions = jsonData?.data?.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects([selectOption, ...projectOptions])
    }
  }, [selectOption])


  async function getCharts(id) {
    const projectData = await getProjectData(id);
    var project = []
    for (let i = 0; i < projectData.data.length; i++) {
      if (projectData.data[i].id === id) {
        project = projectData.data[i]
        break;
      }
    }
    setProjectData(project)
    const jsonData = await getProjectChartData(id);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    setChartData(jsonData.data)
  }

  async function getWBSCharts(id) {
    const jsonData = await getWbsChartData(projectId, id);
    if (jsonData === 'Request failed with status code 401') {
      localStorage.clear();
      window.location.reload(false);
    }
    setChartData(jsonData.data)
  }


  async function getWBS(projectId) {
    var wbsJsonData = await getWBSData(projectId);
    if (!wbsJsonData.data) {
      setNoWBSData(true)
    } else {
      setNoWBSData(false)
    }
    setWbsData(wbsJsonData.data)
    var wbsOptions;
    if (wbsJsonData?.data) {
      wbsOptions = wbsJsonData?.data?.map((wbs) => ({
        value: wbs.id,
        label: wbs.name,
      }));
      setWbsSelect([selectOption, ...wbsOptions])
    } else {
      setWbsId(0)
      setWbsSelect([selectOption])
    }

  }

  useEffect(() => {
    if (wbsId !== null) {
      getDropDownOptions();
    }
    
  }, [wbsId, getDropDownOptions]);

  useEffect(() => {
  }, [projectId]);

  useEffect(() => {
  }, [wbsId]);

  useEffect(() => {
  }, [wbsData]);

  useEffect(() => {
  }, [projectData]);

  useEffect(() => {
    //console.log(chartData)
  }, [chartData]);

  return (
    <>
      <h1>WBS Project Management</h1>
      <p>
        Use this tool to achieve your goals. To start a new WBS project, click on the Add button below.
      </p>
      <div className="form-field control">
        <label>Select a Project</label>
        <Controller
          name="project"
          control={control}
          defaultValue={"0"}
          render={({ field }) =>
            <Select
              {...field}
              onChange={handleChangeProjectSelect}
              value={projectId ? projectId : "0"}
              inputProps={{ "data-testid": "project-select" }}
            >
              {
                projects?.map((project) => {
                  return <MenuItem
                    value={project.value}
                    key={project.value}>
                    {project.label}
                  </MenuItem>
                })
              }
            </Select>
          }
        />
      </div>
      <div className="form-field control">
        <label>Select a WBS</label>
        <Controller
          name="wbs"
          control={control}
          defaultValue={"0"}
          render={({ field }) =>
            <Select
              {...field}
              onChange={handleChangeWbsSelect}
              value={wbsId ? wbsId : "0"}
              inputProps={{ "data-testid": "wbs-select" }}
            >
              {
                wbsSelect?.map((wbs) => {
                  return <MenuItem
                    value={wbs.value}
                    key={wbs.value}>
                    {wbs.label}
                  </MenuItem>
                })
              }
            </Select>
          }
        />
      </div>
      <div className="panel">
        <Suspense fallback={<SpinnerComponent />}>
          <WBSCharts
            chartData={chartData}
            projectData={projectData}
          />
        </Suspense>
        <h2>WBS</h2>
        {noWBSData ? <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
          <Alert severity="error">No WBS found</Alert>
        </Stack> : <div style={{ marginTop: "40px" }}>{projectId ? <Suspense fallback={<SpinnerComponent />}><WBSList projectId={projectId} /></Suspense> : <></>}</div>}
      </div>
    </>
  );
}