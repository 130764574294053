import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import { useForm, Controller } from "react-hook-form";
import { MenuItem, Select } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';

/* This class is for creating and editing a workflow condition */

export async function saveData(name, id, projectId, nodeData, linkData) {
    const currentDate = new Date();
    //call data endpoint for data type to write data
    var url = `${process.env.REACT_APP_API_URL}/workflow_flowchart/${id}`;
    let body = {
        "name": name,
        "node_data": nodeData,
        "link_data": linkData,
        "last_updated_date": currentDate.toISOString(),
        "project_id": projectId
    }
    const response = axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })

    return response;
}

const removeDuplicates = (originalArray) => {
    const uniqueArray = [];
    const seenObjects = new Set();

    originalArray.forEach((obj) => {
        const serializedObj = JSON.stringify(obj);

        if (!seenObjects.has(serializedObj)) {
            uniqueArray.push(obj);
            seenObjects.add(serializedObj);
        }
    });

    return uniqueArray;
};

export default function CreateEditWorkflowCondition({ isOpen, selectedNode, nodeData, linkData, projectId, flowchartId, flowchartName }) {
    // console.log(selectedNode)
    // console.log(nodeData)
    // console.log(linkData)

    const [linksFrom, setLinksFrom] = useState(null);
    const [linksTo, setLinksTo] = useState(null);
    //const [textValues, setTextValues] = useState({});

    const setLinkFromLinkToArr = useCallback(async () => {
        let linksToArr = []
        let linkDataCopy = linkData.map(obj => {
            const newObj = {};
            for (const key in obj) {
                newObj[key] = String(obj[key]);
            }
            return newObj;
        });
        let nodeDataCopy = nodeData.map(obj => {
            const newObj = {};
            for (const key in obj) {
                newObj[key] = String(obj[key]);
            }
            return newObj;
        });
        let linksToData = linkDataCopy.filter((item) => item.from === String(selectedNode.key))
        //get text for link
        linksToData?.map((value) => {
            let node = nodeDataCopy.filter((data) => data?.key === value?.from)
            let newNode = { "node": node[0]?.text, "link_text": value?.text, "node_key": node[0]?.key, "link_key": value?.key, "from": value.from, "to": value.to }
            linksToArr.push(newNode)
            return linksToArr
        })
        linksToArr = removeDuplicates(linksToArr)
        setLinksTo(linksToArr)
        let linksFromArr = []
        let linksFromData = linkDataCopy.filter((item) => item.to === String(selectedNode.key))
        linksFromData?.map((value) => {
            let node = nodeDataCopy.filter((data) => data?.key === value?.to)
            let newNode = { "node": node[0]?.text, "link_text": value?.text, "node_key": node[0]?.key, "link_key": value?.key, "from": value.from, "to": value.to }
            linksFromArr.push(newNode)
            return linksFromArr
        })
        linksFromArr = removeDuplicates(linksFromArr)
        setLinksFrom(linksFromArr)
    }, [linkData, nodeData, selectedNode.key])

    useEffect(() => {
        if (!linksFrom && !linksTo) {
            setLinkFromLinkToArr()
        }
    }, [linksFrom, linksTo, setLinkFromLinkToArr])

    useEffect(() => {

    }, [linksFrom])

    useEffect(() => {

    }, [linksTo])


    const deleteLink = (value, direction) => {
        if (direction === "from") {
            let modifiedLinkArr = [...linksFrom]
            modifiedLinkArr = modifiedLinkArr.filter((item) => item.link_key !== value.link_key)
            setLinksFrom(modifiedLinkArr)
        }
        if (direction === "to") {
            let modifiedLinkArr = [...linksTo]
            modifiedLinkArr = modifiedLinkArr.filter((item) => item.link_key !== value.link_key)
            setLinksTo(modifiedLinkArr)
        }
    }

    const addLink = (direction) => {
        if (direction === "from") {
            let linksFromArr = [...linksFrom]
            let newLink = { "node": "", "link_text": "", "node_key": "", "link_key": uuidv4(), "from": "", "to": selectedNode?.key }
            linksFromArr.push(newLink)
            setLinksFrom(linksFromArr)
        }
        if (direction === "to") {
            let linksToArr = [...linksTo]
            let newLink = { "node": "", "link_text": "", "node_key": "", "link_key": uuidv4(), "from": selectedNode?.key, "to": "" }
            linksToArr.push(newLink)
            setLinksTo(linksToArr)
        }
    }

    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: selectedNode?.text,
        }
    });

    const handleChangeNodeSelect = (value) => {
        if (value?.target?.name === "from") {
            //add to from array
            let linkDataCopy = [...linksFrom]
            linkDataCopy = linkDataCopy.filter((item) => item.from !== "")
            linkDataCopy = linkDataCopy.filter((item) => value?.target?.value !== item.from)
            linkDataCopy.push({ "node": value?.target?.value, "link_text": "", "node_key": value?.target?.value, "link_key": uuidv4(), "from": value?.target?.value, "to": selectedNode?.key })
            setLinksFrom(linkDataCopy)
        }
        if (value?.target?.name === "to") {
            //add to to array
            let linkDataCopy = [...linksTo]
            linkDataCopy = linkDataCopy.filter((item) => item.to !== "")
            linkDataCopy = linkDataCopy.filter((item) => value?.target?.value !== item.to)
            linkDataCopy.push({ "node": selectedNode?.key, "link_text": "", "node_key": value?.target?.value, "link_key": uuidv4(), "from": selectedNode?.key, "to": value?.target?.value })
            setLinksTo(linkDataCopy)
        }
    }

    const handleInputChangeTo = (event) => {
        //update this item in the array
        let link_key_arr = event.target.id.split(":")
        let link_key = link_key_arr[1]
        let linkDataCopy = [...linksTo]
        let linkToUpdate = [...linksTo].filter((item) => item.link_key === link_key)
        linkDataCopy = linkDataCopy.filter((item) => item.link_key !== link_key)

        let updatedLink = {
            "node": linkToUpdate[0]?.node,
            "link_text": event.target.value,
            "node_key": linkToUpdate[0]?.node_key,
            "link_key": link_key,
            "from": linkToUpdate[0]?.from,
            "to": linkToUpdate[0]?.to
        }
        linkDataCopy.push(updatedLink)
        setLinksTo(linkDataCopy)
    };

    const handleInputChangeFrom = (event) => {
        //update this item in the array
        let link_key_arr = event.target.id.split(":")
        let link_key = link_key_arr[1]
        let linkDataCopy = [...linksFrom]
        let linkToUpdate = [...linksFrom].filter((item) => item.link_key === link_key)
        linkDataCopy = linkDataCopy.filter((item) => item.link_key !== link_key)

        let updatedLink = {
            "node": linkToUpdate[0]?.node,
            "link_text": event.target.value,
            "node_key": linkToUpdate[0]?.node_key,
            "link_key": link_key,
            "from": linkToUpdate[0]?.from,
            "to": linkToUpdate[0]?.to
        }
        linkDataCopy.push(updatedLink)
        setLinksFrom(linkDataCopy)
    }

    const updateConditionName = (event) => {
        //console.log(event)
    }

    /* form submit */
    const onSubmit = async (data) => {
        //console.log(linkData)
        //Update node data
        let nodedataCopy = [...nodeData]
        let nodedataCopy2 = [...nodeData]
        let nodeDataToUpdate = nodedataCopy.filter((value) => value.key === selectedNode.key)
        let nodedataCopyArr = nodedataCopy2.filter((value) => value.key !== selectedNode.key)
        const newNode = {
            category: nodeDataToUpdate[0].category,
            from: nodeDataToUpdate[0].from,
            key: nodeDataToUpdate[0].key,
            loc: nodeDataToUpdate[0].loc,
            text: data.name,
            to: nodeDataToUpdate[0].to,
        }
        nodedataCopyArr.push(newNode)
        let finalLinksArr = []
        //go through linksFrom array remove dupes
        let linksFromArr = [...linksFrom]
        linksFromArr?.map((value) => {
            let newLink = { "from": value?.from, "to": value?.to, "key": value?.link_key, "text": value?.link_text }
            finalLinksArr.push(newLink)
            return finalLinksArr
        })
        //go through linksTo array remove dupes
        let linksToArr = [...linksTo]
        linksToArr?.map((value) => {
            let newLink = { "from": value?.from, "to": value?.to, "key": value?.link_key, "text": value?.link_text }
            finalLinksArr.push(newLink)
            return finalLinksArr
        })
        //console.log(finalLinksArr)
        //Update linkData using finalLinksArr
        // let linkDataCopy = linkData.map(obj => {
        //     const newObj = {};
        //     for (const key in obj) {
        //         newObj[key] = String(obj[key]);
        //     }
        //     return newObj;
        // });
        finalLinksArr.map((item) => {
            linkData.push(item)
            return finalLinksArr
        })
        //console.log(linkData)

        const map = new Map();
        let removeDuplicateLinks = linkData.filter(({ from, to }) => {
            if (!(map.get(from) === to)) {
                map.set(from, to);
                return true;
            }
            return false;
        });


        await saveData(flowchartName, flowchartId, projectId, nodedataCopyArr, removeDuplicateLinks)
        isOpen(false)
    }

    return (
        <div className="create edit workflow condition">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field name">
                    <label>Value</label>
                    <Controller
                        name="name"
                        control={control}
                        onChange={updateConditionName}
                        //value={conditionName}
                        render={({ field }) => <TextField required {...field} fullWidth />}
                    />
                </div>
                <h2>Links From <ArrowForwardIcon /></h2>
                <div className="buttons">
                    <Button variant="contained" color="success" className="addLink" onClick={() => { addLink("from") }}>Add</Button>
                </div>
                {linksFrom?.map((value, index) => {
                    return <div className="form-field relationship">
                        <div className="conditional select">
                            <label>From (step, start, or another condition)</label>
                            <Controller
                                name="from"
                                control={control}
                                className="nodeSelect"
                                render={({ field }) =>
                                    <Select
                                        {...field}
                                        onChange={handleChangeNodeSelect}
                                        value={value.from}
                                        id={`link_from:${value.link_key}:link_key`}
                                        className="selectANode"
                                        inputProps={{ "data-testid": "node-select" }}
                                    >
                                        {
                                            nodeData?.map((node) => {
                                                return <MenuItem
                                                    value={node.key}
                                                    key={node.key}>
                                                    {node.text}
                                                </MenuItem>
                                            })
                                        }
                                    </Select>
                                }
                            />
                        </div>
                        <div className="conditional text">
                            {value?.node_key && <><label>Condition (yes, no, equals, does not equal, if, or)</label>
                                <TextField
                                    //{...field}
                                    key={index}
                                    id={`link_from:${value.link_key}:text`}
                                    defaultValue={value.link_text}
                                    onChange={handleInputChangeFrom}
                                    className="conditionText"
                                />
                            </>}
                            <Button variant="contained" className="deleteBtn" startIcon={<DeleteIcon />} onClick={() => { deleteLink(value, "from") }}>Delete</Button>
                        </div>
                    </div>
                })}


                <h2>Links To <ArrowBackIcon /></h2>
                <div className="buttons">
                    <Button variant="contained" color="success" className="addLink" onClick={() => { addLink("to") }}>Add</Button>
                </div>

                {linksTo?.map((value, index) => {
                    return <div className="form-field relationship">
                        <div className="conditional select">
                            <label>To (step, end, or another condition)</label>
                            <Controller
                                name="to"
                                control={control}
                                className="nodeSelect"
                                render={({ field }) =>
                                    <Select
                                        {...field}
                                        onChange={handleChangeNodeSelect}
                                        value={value.to}
                                        id={`link_to:${value.link_key}:link_key`}
                                        className="selectANode"
                                        inputProps={{ "data-testid": "node-select" }}
                                    >
                                        {
                                            nodeData?.map((node) => {
                                                return <MenuItem
                                                    value={node.key}
                                                    key={node.key}>
                                                    {node.text}
                                                </MenuItem>
                                            })
                                        }
                                    </Select>
                                }
                            />
                        </div>
                        <div className="conditional text">
                            {value?.node_key && <><label>Condition (yes, no, equals, does not equal, if, or)</label>

                                <TextField
                                    //{...field}
                                    key={index}
                                    defaultValue={value.link_text}
                                    onChange={handleInputChangeTo}
                                    id={`link_to:${value.link_key}:text`}
                                    className="conditionText"

                                />
                            </>}

                            <Button variant="contained" className="deleteBtn" startIcon={<DeleteIcon />} onClick={() => { deleteLink(value, "to") }}>Delete</Button>
                        </div>
                    </div>

                })}

                <div>
                    <Button type="submit" variant="contained" startIcon={<SaveIcon />} >Save</Button>
                </div>
            </form>
        </div>
    )
}