import React, { useState, useCallback, useEffect, useRef } from 'react';
import * as go from 'gojs';
import { ReactDiagram, ReactPalette } from 'gojs-react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import Fade from '@mui/material/Fade';
import Loader from '../components/Loader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateEditWorkflowCondition from './CreateEditWorkflowCondition';
import CreateEditProjectTask from './CreateEditProjectTask';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '@mui/material';

import '../css/FlowChart.css';  // contains .diagram-component CSS

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export async function loadData(id) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/workflow_flowchart/${id}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })

    return response;
}

export async function getTask(id) {
    var url = `${process.env.REACT_APP_API_URL}/tasks/${id}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })

    return response;
}

export async function updateTask(id, name) {
    var url = `${process.env.REACT_APP_API_URL}/tasks/${id}`;
    let body = {
        "title": name,
        "name": name,
        "comments": "Updated in workflow flowchart editor"
    }
    const response = axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })

    return response;
}

export async function createTask(name, projectId, parents) {
    var url = `${process.env.REACT_APP_API_URL}/tasks/task`;
    let body = {
        "title": name,
        "name": name,
        "user_id": localStorage.getItem("userid"),
        "project_id": projectId,
        "status": "Not Started",
        "priority": "Low",
    }
    if (parents) {
        body = {
            "title": name,
            "name": name,
            "user_id": localStorage.getItem("userid"),
            "project_id": projectId,
            "status": "Not Started",
            "priority": "Low",
            "parents": parents,
        }
    }
    const response = axios(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })

    return response;
}

export async function loadTaskData(id) {
    //call data endpoint for data type to set rows and columns
    var url = `${process.env.REACT_APP_API_URL}/tasks/${id}`;
    const response = axios(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })

    return response;
}


export async function saveData(workflowName, id, projectId, nodeData, linkData) {
    const currentDate = new Date();
    //call data endpoint for data type to write data
    var url = `${process.env.REACT_APP_API_URL}/workflow_flowchart/${id}`;
    let body = {
        "name": workflowName,
        "node_data": nodeData,
        "link_data": linkData,
        "last_updated_date": currentDate.toISOString(),
        "project_id": projectId
        // "start_date": "2025-01-28",
        // "due_date": "2025-01-28"
    }
    const response = axios(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.access_token}`,
        },
        data: body,
    }).then(result => {
        return result;
    }).catch(error => { return error.message; })

    return response;
}

function FadingAlert({ severity, message, duration }) {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setOpen(false);
        }, duration);

        return () => clearTimeout(timeoutId);
    }, [duration]);

    return (
        <Fade in={open} timeout={{ enter: 500, exit: 500 }}>
            <Alert severity={severity} onClose={() => setOpen(false)}>
                {message}
            </Alert>
        </Fade>
    );
}

const removeDuplicates = (originalArray) => {
    const uniqueArray = [];
    const seenObjects = new Set();

    originalArray.forEach((obj) => {
        const serializedObj = JSON.stringify(obj);

        if (!seenObjects.has(serializedObj)) {
            uniqueArray.push(obj);
            seenObjects.add(serializedObj);
        }
    });

    return uniqueArray;
};


export default function FlowChart({ projectId, flowchartId }) {
    const [nodeData, setNodeData] = useState(null);
    const [linkData, setLinkData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [selectedParent, setSelectedParent] = useState(null);
    //const [childBtnVis, setChildBtnVis] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [flowchartName, setFlowchartName] = useState(null);
    const [taskEditOpen, setTaskEditOpen] = useState(false);
    const [taskData, setTaskData] = useState(null);
    const [editName, setEditName] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);

    var diagram = useRef(null);
    var palette = useRef(null);

    const handleClose = () => {
        setEditOpen(false);
        setTaskData(null);
        setTaskEditOpen(false);
        setShowSubMenu(false);
        //window.location.reload(false);
        //setSelectedNode(null);
    }

    const isOpen = () => {
        handleClose();
        //window.location.reload(false);
    }

    const addControlData = async (data) => {
        //update task name in node data array
        let nodeDataCopy = [...nodeData]
        let updateNode = nodeDataCopy.filter((value) => value.key === data.id)
        let nodeDataCopy1 = [...nodeData].filter((value) => value.key !== data.id)
        let newNode = {
            text: data.name,
            key: updateNode[0].key,
            loc: updateNode[0].loc,
            category: updateNode[0].category,
        }
        nodeDataCopy1.push(newNode)
        setNodeData(nodeDataCopy1)
        saveData(flowchartName, flowchartId, projectId, nodeDataCopy1, linkData)
        //update workflow flowchart
    }

    // define some custom shapes for node templates
    function defineFigures() {
        go.Shape.defineFigureGenerator('Conditional', (shape, w, h) => {
            const geo = new go.Geometry();
            const fig = new go.PathFigure(w * 0.15, 0, true);
            geo.add(fig);
            fig.add(new go.PathSegment(go.SegmentType.Line, w * 0.85, 0));
            fig.add(new go.PathSegment(go.SegmentType.Line, w, 0.5 * h));
            fig.add(new go.PathSegment(go.SegmentType.Line, w * 0.85, h));
            fig.add(new go.PathSegment(go.SegmentType.Line, w * 0.15, h));
            fig.add(new go.PathSegment(go.SegmentType.Line, 0, 0.5 * h).close());
            geo.spot1 = new go.Spot(0.15, 0);
            geo.spot2 = new go.Spot(0.85, 1);
            return geo;
        });

        // taken from https://cdn.jsdelivr.net/npm/create-gojs-kit@3.0.18/dist/extensions/Figures.js:
        go.Shape.defineFigureGenerator('File', (shape, w, h) => {
            const geo = new go.Geometry();
            const fig = new go.PathFigure(0, 0, true); // starting point
            geo.add(fig);
            fig.add(new go.PathSegment(go.SegmentType.Line, 0.75 * w, 0));
            fig.add(new go.PathSegment(go.SegmentType.Line, w, 0.25 * h));
            fig.add(new go.PathSegment(go.SegmentType.Line, w, h));
            fig.add(new go.PathSegment(go.SegmentType.Line, 0, h).close());
            const fig2 = new go.PathFigure(0.75 * w, 0, false);
            geo.add(fig2);
            // The Fold
            fig2.add(new go.PathSegment(go.SegmentType.Line, 0.75 * w, 0.25 * h));
            fig2.add(new go.PathSegment(go.SegmentType.Line, w, 0.25 * h));
            geo.spot1 = new go.Spot(0, 0.25);
            geo.spot2 = go.Spot.BottomRight;
            return geo;
        });
    }

    function initDiagram() {
        defineFigures();
        // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
        diagram =
            new go.Diagram(
                {
                    'undoManager.isEnabled': true,  // must be set to allow for model change listening
                    //'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
                    'clickCreatingTool.archetypeNodeData': { text: 'new node', color: 'lightblue' },
                    model: new go.GraphLinksModel(
                        {
                            linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
                        })
                });
        // set up some colors/fonts for the default ('light') and dark Themes
        diagram.themeManager.set('light', {
            colors: {
                text: '#fff',
                start: '#064e3b',
                parent: '#0074A6',
                conditional: '#009CD1',
                child: '#007EB3',
                end: '#7f1d1d',
                comment: '#a691cc',
                bgText: '#000',
                link: '#dcb263',
                linkOver: '#cbd5e1',
                div: '#ede9e0'
            }
        });

        // helper definitions for node templates
        function nodeStyle(node) {
            node
                // the Node.location is at the center of each node
                .set({ locationSpot: go.Spot.Center })
                // The Node.location comes from the "loc" property of the node data,
                // converted by the Point.parse static method.
                // If the Node.location is changed, it updates the "loc" property of the node data,
                // converting back using the Point.stringify static method.
                .bindTwoWay('location', 'loc', go.Point.parse, go.Point.stringify);
        }

        function shapeStyle(shape) {
            // make the whole node shape a port
            shape.set({ strokeWidth: 0, portId: '', cursor: 'pointer' });
        }

        function textStyle(textblock) {
            textblock.set({ font: 'bold 11pt Figtree, sans-serif' }).theme('stroke', 'text');
        }

        const $ = go.GraphObject.make;  // Alias for better readability

        diagram.nodeTemplate =
            $(go.Node, 'Auto',  // Node type
                new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
                $(go.Shape, 'Rectangle',
                    {
                        name: 'SHAPE',
                        fill: 'lightblue',
                        strokeWidth: 0,
                        fromLinkable: true,
                        toLinkable: true,
                        fromSpot: go.Spot.AllSides,
                        toSpot: go.Spot.AllSides
                    },
                    new go.Binding('fill', 'color')
                ),
                $(go.TextBlock,
                    {
                        margin: 12,
                        maxSize: new go.Size(160, NaN),
                        wrap: go.Wrap.Fit,
                        editable: true
                    },
                    new go.Binding('text').makeTwoWay()
                )
            );

        //Parent Node
        var parent;
        diagram.nodeTemplateMap.add(
            'Parent',
            new go.Node('Auto',
                {
                    contextClick: (e, obj) => {
                        setSelectedParent(obj.part.data.key)
                        parent = obj.part.data.key
                        // Manually show the context menu
                        obj.part.adornments.contextMenu = obj.part.contextMenu;
                        //obj.part.adornments.contextMenu = createMainContextMenu(obj);
                        //e.diagram.commandHandler.showContextMenu(obj.part);
                    },
                    //contextMenu: createMainContextMenu(obj),
                    contextMenu: $(go.Adornment, 'Vertical',
                        $(
                            "ContextMenuButton",  // Correct way to define a context menu button
                            {
                                "ButtonBorder.fill": "white",
                                "_buttonFillOver": "skyblue",
                                click: (e, obj) => {

                                    setTaskEditOpen(true);
                                }
                            },
                            $(go.TextBlock, "Edit Task", { margin: 10 })
                        ),
                        $(
                            "ContextMenuButton",  // Correct way to define a context menu button
                            {
                                "ButtonBorder.fill": "white",
                                "_buttonFillOver": "skyblue",
                                click: (e, obj) => {
                                    setEditOpen(true);
                                }
                            },
                            $(go.TextBlock, "Edit Links", { margin: 10 })
                        ),
                        $(
                            "ContextMenuButton",  // Correct way to define a context menu button
                            {
                                "ButtonBorder.fill": "white",
                                "_buttonFillOver": "skyblue",
                                click: async(e, obj) => {
                                    await addChildToParent(parent)
                                }
                            },
                            $(go.TextBlock, "Add Child Task", { margin: 10 })
                        ),
                        $(
                            "ContextMenuButton",  // Correct way to define a context menu button
                            {
                                "ButtonBorder.fill": "white",
                                "_buttonFillOver": "skyblue",
                                click: (e, obj) => {
                                    setShowSubMenu(true);
                                }
                            },
                            $(go.TextBlock, "Add Event", { margin: 10 })
                        ),
                        $(
                            "ContextMenuButton",  // Correct way to define a context menu button
                            {
                                "ButtonBorder.fill": "white",
                                "_buttonFillOver": "skyblue",
                                click: (e, obj) => {
                                    //setEditOpen(true);
                                }
                            },
                            $(go.TextBlock, "Switch to Form Designer", { margin: 10 })
                        )
                    )
                },
            )
                .apply(nodeStyle)
                .add(
                    new go.Shape('RoundedRectangle',
                        {
                            fromLinkable: true,
                            toLinkable: true
                        }
                    ).apply(shapeStyle).theme('fill', 'parent'),
                    new go.TextBlock({
                        margin: 8,
                        maxSize: new go.Size(160, NaN),
                        wrap: go.Wrap.Fit,
                        textAlign: 'center',
                        editable: true
                    })
                        .apply(textStyle)
                        .bindTwoWay('text')
                )
        );

        diagram.nodeTemplateMap.add(
            'Conditional',
            $(go.Node, 'Auto',
                {
                    contextMenu: $(go.Adornment, 'Vertical',
                        $(
                            "ContextMenuButton",  // Alternative way to define the button
                            {
                                "ButtonBorder.fill": "white",
                                "_buttonFillOver": "skyblue",
                                click: (e, obj) => {
                                    setEditOpen(true)
                                }
                            },
                            $(go.TextBlock, "Edit Links", { margin: 10 })
                        ),
                        $(
                            "ContextMenuButton",  // Correct way to define a context menu button
                            {
                                "ButtonBorder.fill": "white",
                                "_buttonFillOver": "skyblue",
                                click: (e, obj) => {
                                    setShowSubMenu(true);
                                }
                            },
                            $(go.TextBlock, "Add Event", { margin: 10 })
                        ),
                        $(
                            "ContextMenuButton",  // Correct way to define a context menu button
                            {
                                "ButtonBorder.fill": "white",
                                "_buttonFillOver": "skyblue",
                                click: (e, obj) => {
                                }
                            },
                            $(go.TextBlock, "Switch to Form Designer", { margin: 10 })
                        )
                    )
                }
            )
                .apply(nodeStyle)
                .add(
                    $(go.Shape, 'Conditional', { fromLinkable: true, toLinkable: true }).apply(shapeStyle).theme('fill', 'conditional'),
                    $(go.TextBlock,
                        {
                            margin: 10,
                            maxSize: new go.Size(160, NaN),
                            wrap: go.Wrap.Fit,
                            textAlign: 'center',
                            editable: true
                        },
                        new go.Binding('text').makeTwoWay()
                    ).apply(textStyle)
                )
        );


        diagram.nodeTemplateMap.add(
            'Child',
            new go.Node('Auto', {
                contextMenu: $(go.Adornment, 'Vertical',
                    $(
                        "ContextMenuButton",  // Correct way to define a context menu button
                        {
                            "ButtonBorder.fill": "white",
                            "_buttonFillOver": "skyblue",
                            click: (e, obj) => {

                                setTaskEditOpen(true);
                            }
                        },
                        $(go.TextBlock, "Edit Task", { margin: 10 })
                    ),
                    $(
                        "ContextMenuButton",  // Alternative way to define the button
                        {
                            "ButtonBorder.fill": "white",
                            "_buttonFillOver": "skyblue",
                            click: (e, obj) => {
                                setEditOpen(true)
                            }
                        },
                        $(go.TextBlock, "Edit Links", { margin: 10 })
                    ),
                    $(
                        "ContextMenuButton",  // Correct way to define a context menu button
                        {
                            "ButtonBorder.fill": "white",
                            "_buttonFillOver": "skyblue",
                            click: (e, obj) => {
                                setShowSubMenu(true);
                            }
                        },
                        $(go.TextBlock, "Add Event", { margin: 10 })
                    ),
                    $(
                        "ContextMenuButton",  // Correct way to define a context menu button
                        {
                            "ButtonBorder.fill": "white",
                            "_buttonFillOver": "skyblue",
                            click: (e, obj) => {
                                //setEditOpen(true);
                            }
                        },
                        $(go.TextBlock, "Switch to Form Designer", { margin: 10 })
                    )
                )
            })
                .apply(nodeStyle)
                .add(
                    new go.Shape('RoundedRectangle',
                        {
                            fromLinkable: true,
                            toLinkable: true
                        }).apply(shapeStyle).theme('fill', 'child'),
                    new go.TextBlock({
                        margin: 10,
                        maxSize: new go.Size(160, NaN),
                        wrap: go.Wrap.Fit,
                        textAlign: 'center',
                        editable: true
                    })
                        .apply(textStyle)
                        .bindTwoWay('text')
                )
        );
        diagram.nodeTemplateMap.add(
            'Start',
            new go.Node('Auto', {
                contextMenu: $(go.Adornment, 'Vertical',
                    $(
                        "ContextMenuButton",  // Alternative way to define the button
                        {
                            "ButtonBorder.fill": "white",
                            "_buttonFillOver": "skyblue",
                            click: (e, obj) => {
                                setEditOpen(true)
                            }
                        },
                        $(go.TextBlock, "Edit Links", { margin: 10 })
                    ),
                    $(
                        "ContextMenuButton",  // Correct way to define a context menu button
                        {
                            "ButtonBorder.fill": "white",
                            "_buttonFillOver": "skyblue",
                            click: (e, obj) => {
                                setShowSubMenu(true);
                            }
                        },
                        $(go.TextBlock, "Add Event", { margin: 10 })
                    )
                )
            })
                .apply(nodeStyle)
                .add(
                    new go.Shape('Capsule', { fromLinkable: true }).apply(shapeStyle).theme('fill', 'start'),
                    new go.TextBlock('Start', {
                        margin: new go.Margin(5, 6),
                        editable: true
                    }).apply(textStyle).bindTwoWay('text')
                )
        );

        diagram.nodeTemplateMap.add(
            'End',
            new go.Node('Auto', {
                contextMenu: $(go.Adornment, 'Vertical',
                    $(
                        "ContextMenuButton",  // Alternative way to define the button
                        {
                            "ButtonBorder.fill": "white",
                            "_buttonFillOver": "skyblue",
                            click: (e, obj) => {
                                setEditOpen(true)
                            }
                        },
                        $(go.TextBlock, "Edit Links", { margin: 10 })
                    ),
                    $(
                        "ContextMenuButton",  // Correct way to define a context menu button
                        {
                            "ButtonBorder.fill": "white",
                            "_buttonFillOver": "skyblue",
                            click: (e, obj) => {
                                setShowSubMenu(true);
                            }
                        },
                        $(go.TextBlock, "Add Event", { margin: 10 })
                    ),
                )
            })
                .apply(nodeStyle)
                .add(
                    new go.Shape('Capsule', { toLinkable: true }).apply(shapeStyle).theme('fill', 'end'),
                    new go.TextBlock('End', { margin: new go.Margin(5, 6), editable: true }).apply(textStyle).bindTwoWay('text')
                )
        );

        diagram.nodeTemplateMap.add(
            'Comment',
            new go.Node('Auto').apply(nodeStyle)
                .add(
                    new go.Shape('File', { strokeWidth: 3 }).theme('fill', 'div').theme('stroke', 'comment'),
                    new go.TextBlock({
                        font: '9pt Figtree, sans-serif',
                        margin: 8,
                        maxSize: new go.Size(200, NaN),
                        wrap: go.Wrap.Fit,
                        textAlign: 'center',
                        editable: true
                    })
                        .theme('stroke', 'bgText')
                        .bindTwoWay('text')
                    // no ports, because no links are allowed to connect with a comment

                )
        );
        // replace the default Link template in the linkTemplateMap
        diagram.linkTemplate = new go.Link({
            routing: go.Routing.AvoidsNodes,
            curve: go.Curve.JumpOver,
            corner: 5,
            toShortLength: 4,
            relinkableFrom: true,
            relinkableTo: true,
            reshapable: true,
            resegmentable: true,
            // mouse-overs subtly highlight links:
            // mouseEnter: (e, link) => (link.findObject('HIGHLIGHT').stroke = link.diagram.themeManager.findValue('linkOver', 'colors')),
            // mouseLeave: (e, link) => (link.findObject('HIGHLIGHT').stroke = 'transparent'),
            // context-click creates an editable link label
            contextClick: (e, link) => {
                e.diagram.model.commit((m) => {
                    m.set(link.data, 'text', 'Label');
                });
            }
        })
            .bindTwoWay('points')
            .add(
                // the highlight shape, normally transparent
                new go.Shape({
                    isPanelMain: true,
                    strokeWidth: 8,
                    stroke: 'transparent',
                    name: 'HIGHLIGHT'
                }),
                // the link path shape
                new go.Shape({ isPanelMain: true, strokeWidth: 2 })
                    .theme('stroke', 'link'),
                // the arrowhead
                new go.Shape({ toArrow: 'standard', strokeWidth: 0, scale: 1.5 })
                    .theme('fill', 'link'),
                // the link label
                new go.Panel('Auto', { visible: false })
                    .bind('visible', 'text', (t) => typeof t === 'string' && t.length > 0) // only shown if there is text
                    .add(
                        // a gradient that fades into the background
                        new go.Shape('Ellipse', { strokeWidth: 0 })
                            .theme('fill', 'div', null, null, c => new go.Brush("Radial", { 0: c, 0.5: `${c}00` })),
                        new go.TextBlock({
                            name: 'LABEL',
                            font: '9pt Figtree, sans-serif',
                            margin: 3,
                            editable: true
                        })
                            .theme('stroke', 'bgText')
                            .bindTwoWay('text')
                    )
            );
        diagram.addDiagramListener("ObjectSingleClicked",
            e => {
                const part = e.subject.part;
                if (!(part instanceof go.Link)) {
                    setSelectedNode(part.data)
                    if (part.data.category === "Parent" || part.data.category === "Child") {
                        setSelectedParent(part.data.key)
                    }
                    else {
                    }
                }
            });
        diagram.addDiagramListener("BackgroundSingleClicked",
            e => {
                setSelectedParent(null);
            });


        // temporary links used by LinkingTool and RelinkingTool are also orthogonal:
        diagram.toolManager.linkingTool.temporaryLink.routing = go.Routing.Orthogonal;
        diagram.toolManager.relinkingTool.temporaryLink.routing = go.Routing.Orthogonal;
        return diagram;
    }

    function initPalette() {
        const $ = go.GraphObject.make;

        // Create a new palette
        palette = $(go.Palette);

        // Define the Node template for the palette
        palette.nodeTemplate = $(
            go.Node,
            "Auto", // Automatically arranges Shape and TextBlock
            {
            },
            new go.Binding("visible", "isVisible"),
            new go.Shape("RoundedRectangle", { fill: "#90d5ff", stroke: null, margin: 10 }).bind("fill", "color").bind("visible", "visible"),
            new go.Panel("Table", { defaultAlignment: go.Spot.Left, margin: 4 })
                .addColumnDefinition(1, { width: 150, height: 100 })
                .add(
                    new go.TextBlock(
                        {
                            stroke: '#fff',
                            margin: 10,
                            row: 0,
                            column: 0,
                            spacingAbove: 2,
                        }
                    ).bind("text", "symbol").bind("font"),
                    new go.TextBlock(
                        {
                            margin: 0, // Margin around the text
                            editable: true, // Allow text editing
                            stroke: "white",
                            textAlign: "right",
                            font: "bold 12pt sans-serif",
                            spacingBelow: 2,
                            row: 0,
                            column: 1

                        },
                    ).bind("text", "text"),

                )
        );


        // Define the palette's model with initial nodes
        palette.model = new go.GraphLinksModel([
            /*{ key: "Start", category: "Start", text: "Start" },*/
            { key: "Parent", text: "Parent Task", category: "Parent", color: "#0074A6", symbol: '\ue84e', font: "14pt Material Symbols Outlined", isVisible: true, completed: false },
            { key: "Child", text: "Child Task", category: "Child", color: "#007EB3", symbol: '\ue87c', font: "14pt Material Symbols Outlined", isVisible: false, completed: false  },
            { key: "Conditional", category: "Conditional", text: "Condition", color: "#009CD1", symbol: '\ue887', font: "14pt Material Symbols Outlined", isVisible: true, completed: false  },
            /*{ key: "End", category: "End", text: "End" },*/
            { key: "Comment", category: "Comment", text: "Comment", color: "#0074A6", isVisible: true },
        ]);

        return palette;
    }

    /**
     * This function handles any changes to the GoJS model.
     * It is here that you would make any updates to your React state, which is discussed below.
     */
    async function handleModelChange(changes) {
        //console.log(`GoJS model changed! ${JSON.stringify(changes)}`);
        if (changes?.insertedNodeKeys?.length === 1 && changes?.insertedNodeKeys?.length === 1) {
            //create a new task
            if (changes.modifiedNodeData[0].category === 'Parent') {
                const response = await createTask(changes.modifiedNodeData[0].text, projectId, null)
                let nodeDataCopy = [...nodeData];
                nodeDataCopy = nodeDataCopy.filter((item) => item.key !== changes?.insertedNodeKeys[0].key)
                let newNode = { text: changes.modifiedNodeData[0].text, key: response.data.id, loc: changes.modifiedNodeData[0].loc, category: changes.modifiedNodeData[0].category, completed: changes.modifiedNodeData[0].completed }
                nodeDataCopy.push(newNode)
                setNodeData([...nodeDataCopy]);
            }
            else {
                let nodeDataCopy = [...nodeData];
                let newNode = { category: changes.modifiedNodeData[0].category, key: changes.modifiedNodeData[0].key, text: changes.modifiedNodeData[0].text, loc: changes.modifiedNodeData[0].loc, from: true, to: true, completed: changes.modifiedNodeData[0].completed  }
                nodeDataCopy.push(newNode)
                setNodeData([...nodeDataCopy]);
            }

        }
        if (changes?.insertedLinkKeys !== null && changes?.insertedLinkKeys?.length === 1) {

            let linkDataCopy = [...linkData];
            let newLink = { from: changes?.modifiedLinkData[0].from, to: changes?.modifiedLinkData[0].to }
            linkDataCopy.push(newLink)
            setLinkData([...linkDataCopy])

        }
        if (changes?.modifiedNodeData && !changes?.insertedNodeKeys && changes?.modifiedNodeData?.length === 1) {
            if (changes?.modifiedNodeData[0].category === 'Parent' || changes?.modifiedNodeData[0].category === 'Child') {
                const result = await updateTask(parseInt(changes?.modifiedNodeData[0]?.key), changes?.modifiedNodeData[0]?.text);
                let nodeDataCopy = [...nodeData];
                nodeDataCopy = nodeDataCopy.filter((item) => item.key !== changes?.modifiedNodeData[0].key)
                let newNode = { text: changes.modifiedNodeData[0].text, key: result.data.id, loc: changes.modifiedNodeData[0].loc, from: true, to: true, category: changes?.modifiedNodeData[0].category, completed: changes.modifiedNodeData[0].completed }
                nodeDataCopy.push(newNode)
                setNodeData([...nodeDataCopy]);

            }
            else {
                let nodeDataCopy = [...nodeData];
                nodeDataCopy = nodeDataCopy.filter((item) => item.key !== changes?.modifiedNodeData[0].key)
                let newNode = { category: changes.modifiedNodeData[0].category, key: changes.modifiedNodeData[0].key, text: changes.modifiedNodeData[0].text, loc: changes.modifiedNodeData[0].loc, from: true, to: true, completed: changes.modifiedNodeData[0].completed }
                nodeDataCopy.push(newNode)
                setNodeData([...nodeDataCopy]);
            }
        }

        if (changes?.modifiedLinkData?.length === 1) {
            let linkDataCopy = [...linkData];
            linkDataCopy = linkDataCopy.filter((item) => item.key !== changes?.modifiedLinkData[0].key)
            let newLink = { from: changes?.modifiedLinkData[0].from, to: changes?.modifiedLinkData[0].to, key: changes?.modifiedLinkData[0].key }
            if (changes?.modifiedLinkData[0].text) {
                newLink = { from: changes?.modifiedLinkData[0].from, to: changes?.modifiedLinkData[0].to, key: changes?.modifiedLinkData[0].key, text: changes?.modifiedLinkData[0].text }
            }
            
            linkDataCopy.push(newLink)
            setLinkData([...linkDataCopy])
        }
        if (changes?.modifiedLinkData?.length > 1) {
            let linkDataCopy;
            changes.modifiedLinkData.map((link) => {
                linkDataCopy = [...linkData];
                linkDataCopy = linkDataCopy.filter((item) => item.key !== link.key)
                let newLink = { from: link.from, to: link.to, key: link.key }
                if (link.text) {
                    newLink = { from: link.from, to: link.to, key: link.key, text: link.text }
                }
                
                linkDataCopy.push(newLink)
                setLinkData([...linkDataCopy])
                return linkDataCopy
            })

        }
        // If nodes removed
        if (changes?.removedNodeKeys?.length && changes?.removedNodeKeys?.length === 1) {
            var newNodes = [...nodeData]
            // Create a new array without the item to be removed
            newNodes = newNodes.filter((elem) => elem.key !== changes?.removedNodeKeys?.[0]);
            // Update the state with the new array
            setNodeData(newNodes);
        }
        if (changes?.removedNodeKeys?.length && changes?.removedNodeKeys?.length > 1) {
            //remove data from array that matches key
            changes?.removedNodeKeys?.forEach((item) => {
                // Create a new array without the item to be removed
                var newNodes = [...nodeData]
                newNodes = newNodes.filter((elem) => elem.key !== item);
                // Update the state with the new array
                setNodeData(newNodes);
            })
        }
        // If links removed
        if (changes?.removedLinkKeys && changes?.removedLinkKeys?.length === 1) {
            //remove data from array that matches key
            var newLinks = [...linkData]
            // Create a new array without the item to be removed
            newLinks = newLinks.filter((elem) => elem.key !== changes?.removedLinkKeys[0]);
            // Update the state with the new array
            setLinkData(newLinks);
        }
        if (changes?.removedLinkKeys && changes?.removedLinkKeys?.length > 1) {
            //remove data from array that matches key
            changes?.removedLinkKeys?.map((item) => {
                var newLinks = [...linkData]
                // Create a new array without the item to be removed
                newLinks = newLinks.filter((elem) => elem.key !== item);
                // Update the state with the new array
                setLinkData(newLinks);
                return linkData
            })
        }

    }

    const addChildToParent = async (parent) => {
        let parentTask = await getTask(parent)
        // create task in db with child parent relationship
        let parentArr = [];
        parentArr.push(parent)
        const response = await createTask("Child Task", projectId, parentArr)
        let nodeDataCopy = [...nodeData];
        let newParent = { text: "New Parent", loc: "-100.00 -400.00", key: parentTask.data.id, category: "Parent", completed: false }
        nodeDataCopy.push(newParent)
        let newNode = { text: "New Child", loc: "0.00 -300.00", key: response.data.id, category: "Child", parent: parent, completed: false }
        nodeDataCopy.push(newNode)
        let linkKey = "parent-child-" + parent + response.data.id;
        let newLink = {
            "from": parent,
            "to": response.data.id,
            "key": linkKey,
            "text": "Text"
        }
        let linkDataCopy = [...linkData];
        linkDataCopy.push(newLink)
        await saveData(flowchartName, flowchartId, projectId, nodeDataCopy, linkDataCopy)
        await getData()
        //window.location.reload(false)
    }

    const saveDiagram = useCallback(async () => {
        let removeNodeDataDupes = removeDuplicates(nodeData)
        const response = await saveData(flowchartName, flowchartId, projectId, removeNodeDataDupes, linkData);
        nodeData?.map(async (object) => {
            if (!object.hasOwnProperty('category')) {
                //get task to see if name is different
                const task = await getTask(parseInt(object?.key))
                if (task?.data) {
                    if (task.data.name !== object?.text) {
                        const result = await updateTask(parseInt(object?.key), object?.text);
                        if (result.data) {
                        }
                    }
                }


            }
        })

        if (response.status === 200) {
            setSuccessMsg("Saved diagram")
        } else {
            setErrMsg(`${response.status}`)
        }
    }, [flowchartId, linkData, nodeData, projectId, flowchartName])


    const getData = useCallback(async () => {
        setLoading(true);
        const jsonData = await loadData(flowchartId);
        if (jsonData.status !== 200) {
            //handle the error
            setErrMsg(`No workflow exists with this ID`)
            setLoading(false);
        }
        if (jsonData.status === 200 && jsonData.data) {
            setFlowchartName(jsonData.data.name)
            let node_data = JSON.stringify(jsonData.data.node_data)
            let node_data_json = JSON.parse(node_data)
            setNodeData(node_data_json)
            // let link_data = JSON.stringify(jsonData.data.link_data)
            // let link_data_json = JSON.parse(link_data)
            setLinkData(jsonData.data.link_data)
            setLoading(false)
        }
    }, [flowchartId])

    const getTaskData = useCallback(async (parent) => {
        const jsonData = await loadTaskData(parent);
        if (jsonData.status !== 200) {
            //handle the error
            setErrMsg(`No task exists with this ID`)
            //setLoading(false);
        }
        if (jsonData.status === 200 && jsonData.data) {
            setTaskData(jsonData.data)
        }
    }, [])

    useEffect(() => {
        if (!nodeData && !linkData) {
            getData();
        }
    }, [nodeData, linkData, getData]);

    useEffect(() => {
    }, [selectedParent]);

    useEffect(() => {
    }, [errMsg])

    useEffect(() => {
    }, [successMsg])

    useEffect(() => {
        if (selectedParent && !taskData) {
            getTaskData(selectedParent);
        }
    }, [selectedParent, getTaskData, taskData])

    if (loading) {
        return (
            <Loader />
        )
    }

    const updateWorkFlowName = (e) => {
        setFlowchartName(e.target.value);
    }

    const saveNewWorkflowName = () => {
        saveData(flowchartName, flowchartId, projectId, nodeData, linkData);
        setEditName(false);
    }

    return (
        <>
            {errMsg ? (
                <Stack sx={{ width: '100%' }} spacing={2} className="alerts">
                    <Alert severity="error">{errMsg}</Alert>
                </Stack>
            ) : (
                <></>
            )}

            <div>
                {editName ? <><h2><TextField label="Flowchart Name" name="workflowName" defaultValue={flowchartName} onChange={updateWorkFlowName}></TextField><Button startIcon={<SaveIcon />} onClick={saveNewWorkflowName} /></h2></> : <h2>{flowchartName} <Button startIcon={<EditIcon />} onClick={() => { setEditName(true); }} /></h2>}

                <Button startIcon={<SaveIcon />} onClick={saveDiagram}>Save</Button>
                <Button startIcon={<SwitchAccessShortcutIcon />} >Switch to Form Designer</Button>
                {successMsg ? (
                    <>
                        <div style={{ position: 'absolute', float: 'right' }}>
                            <FadingAlert severity="success" message={successMsg} duration={3000} />
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {/*open &&
                    <>
                        <div style={{ position: 'absolute', float: 'right' }}>
                            <FadingAlert severity="success" message="Task was updated" duration={3000} />
                        </div>
                    </>
                */}
                <ReactPalette
                    ref={palette}
                    initPalette={initPalette}
                    divClassName='paletteDiv'
                />
                {/*childBtnVis && <div className="addChildBtn"><Button startIcon={<AddIcon />} onClick={addChildToParent}>Add Child to selected parent</Button></div>*/}
                <ReactDiagram
                    initDiagram={() => initDiagram()}
                    divClassName='diagram-component'
                    div='diagramDiv'
                    nodeDataArray={nodeData}
                    linkDataArray={linkData}
                    onModelChange={handleModelChange}
                    ref={diagram}
                //modifiedNodeData={handleModelChange}
                />
                <div id="modal-root"></div>
                ...
            </div>
            <Dialog
                fullScreen
                open={editOpen}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className={`workflow dialog edit`}
            >
                <DialogActions className="create-edit-dialog">
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
                <DialogTitle>Edit Condition</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <CreateEditWorkflowCondition isOpen={isOpen} flowchartName={flowchartName} flowchartId={flowchartId} selectedNode={selectedNode} nodeData={nodeData} linkData={linkData} projectId={projectId} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {selectedParent ? <Dialog
                fullScreen
                open={taskEditOpen}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className={`workflow dialog edit`}
            >
                <DialogActions className="create-edit-dialog">
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
                <DialogTitle>Edit Task</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <CreateEditProjectTask isOpen={isOpen} flowchartName={flowchartName} controlData={addControlData} flowchartId={flowchartId} editDataObj={taskData} nodeData={nodeData} linkData={linkData} projId={projectId} />
                    </DialogContentText>
                </DialogContent>
            </Dialog> : <></>}

            <Dialog
                //fullScreen
                open={showSubMenu}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className={`workflow dialog edit`}
            >
                <DialogActions className="create-edit-dialog">
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
                <DialogTitle>Choose an event to trigger</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Button>Add New Risk</Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Button>Send Email</Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Button>Add New Task</Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Button>Add Audit Test</Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}